@import '../../../styles/vars';

.wrapper {
  margin-top: 30px;
  background-color: #6F6BD1;
  background-image: url('/img/about-us-background.webp');
  background-size: cover;
  background-position: center;
  color: $color-white;
  padding: 60px 0;
}
.logo {
  display: block;
  width: 300px;
  height: auto;
  margin: 0 auto;
}
.textWrapper {
  margin-top: 40px;
}
.paragraph {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.button {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 300px;
}