@import "../../../../styles/vars.scss";

.services {
  margin-top: 50px;
}

.title {
  font-family: $font-oswald;
  color: $color-primary;
  font-weight: $font-oswald-weight-bold;
  font-size: 50px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 40px;
  }
}
