@import "../../../styles/vars";

.wrapper {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $desktop-width) {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
.title {
  font-size: 48px;
  font-family: $font-oswald;
  line-height: 1;
  padding: 5px 0;
  padding-left: 20px;
  position: relative;
  margin-bottom: 50px;
  @media (max-width: $desktop-width) {
    margin-bottom: 25px;
    font-size: 36px;
  }
  &:before {
    content: "";
    width: 4px;
    height: 100%;
    display: block;
    background: $color-secondary;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.titleHighlight {
  display: block;
  color: $color-primary;
  text-transform: uppercase;
  font-size: 90px;
  @media (max-width: $desktop-width) {
    font-size: 70px;
  }
}
.highlightsWrapper {
  max-width: 550px;
  @media (max-width: $desktop-width) {
    max-width: initial;
  }
}
.highlightTitle {
  font-size: 20px;
  color: $color-primary;
  font-family: $font-oswald;
  font-weight: $font-oswald-weight-bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  @media (max-width: $desktop-width) {
    font-size: 18px;
  }
}
.highlightDescription {
  margin-bottom: 15px;
}
.button {
  max-width: 250px;
  margin-top: 30px;
}
