@import "../../../../styles/vars.scss";

.hasBackground {
  &.serviceWrapper {
    background: $color-gradient;
  }
  .serviceTitle,
  .itemTitle,
  .itemText {
    color: $color-white;
  }
  .divider {
    background: $color-white;
  }
}

.serviceWrapper {
  padding: 10px 0;
  background: $color-white;
}

.serviceTitle {
  color: $color-primary;
  font-size: 30px;
  text-transform: uppercase;
  font-family: $font-oswald;
  margin-top: 30px;
  margin-bottom: 40px;
  @media (max-width: $tablet-width) {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
.item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: $tablet-width) {
    flex-direction: column;
  }
}

.iconWrapper {
  width: 60px;
  margin-right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $tablet-width) {
    margin-bottom: 10px;
    margin-right: 0;
  }
}
.itemIcon {
  width: 60px;
  max-width: 60px;
  max-height: 60px;
  @media (max-width: $tablet-width) {
    width: 50px;
    max-width: 50px;
    max-height: 50px;
  }
}

.itemTitle {
  text-transform: uppercase;
  color: $color-primary;
  font-weight: bold;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  margin-right: 20px;
  @media (max-width: $tablet-width) {
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;
    min-width: initial;
    max-width: initial;
    text-align: center;
  }
}

.itemText {
  color: $color-black;
}

.divider {
  height: 1px;
  background: linear-gradient(
    149deg,
    rgba($color-primary-rgb, 1) 0%,
    rgba($color-secondary-rgb, 1) 100%
  );
  margin-bottom: 40px;
  @media (max-width: $tablet-width) {
    margin-bottom: 30px;
  }
}
