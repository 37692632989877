@import "../../../styles/vars";

.opening {
  height: calc(100vh - 70px);
  background-color: #2f81b3;
  background-image: url("/img/opening-background.webp");
  background-size: cover;
  background-position: center;
  @media (max-width: $desktop-width) {
    height: initial;
  }
}
.openingInner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 70px);
  color: $color-white;
  @media (max-width: $desktop-width) {
    height: initial;
    flex-direction: column;
    padding: 160px 0;
  }
}
.mainTextWrapper {
  max-width: 485px;
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  @media (max-width: $desktop-width) {
    width: 100%;
    margin-right: 0;
  }
}
.h1 {
  font-family: $font-oswald;
  font-size: 40px;
  font-weight: $font-oswald-weight-bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  @media (max-width: 600px) {
    font-size: 30px;
  }
}
.h2 {
  font-size: 28px;
  @media (max-width: 600px) {
    font-size: 22px;
  }
}
.highlightsWrapper {
  width: calc(100% - 15% - 485px);
  font-size: 22px;
  font-family: $font-oswald;
  font-weight: normal;
  @media (max-width: $desktop-width) {
    width: 100%;
    max-width: 485px;
    margin-top: 40px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
}
.highlight {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.smile {
  width: 65px;
  height: auto;
  margin-right: 10px;
}
.highlightText {
  display: block;
}
.callToActionButton {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  &:hover {
    transform: translateX(-50%) scale(1.1) !important;
  }
  @media (max-width: $desktop-width) {
    bottom: 40px;
  }
}
